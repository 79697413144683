// src/components/Increment3.tsx
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store";
import { increment3, decrement3 } from "../counterSlice";

const Increment3: React.FC = () => {
  const count3 = useSelector((state: RootState) => state.counter.count3);
  const dispatch = useDispatch();

  return (
    <div className="flex items-center justify-center rounded-md bg-white p-2">
      <button
        onClick={() => dispatch(decrement3())}
        className="text-xl px-3 py-1 border rounded-l-md bg-gray-200 hover:bg-gray-300"
      >
        -
      </button>
      <span className="text-xl mx-4">{count3}</span>
      <button
        onClick={() => dispatch(increment3())}
        className="text-xl px-3 py-1 border rounded-r-md bg-gray-200 hover:bg-gray-300"
      >
        +
      </button>
    </div>
  );
};

export default Increment3;

import React, { useEffect, useState } from "react";
import googleIcon from "../img/google.png";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { createUserWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "../firebase";
import signupIllustration from "../img/signupfemme.png";
import { useNavigate } from "react-router-dom";
import logo from "../img/lgfm.jpg"; // Add your logo path
import { BsQuestionCircle } from "react-icons/bs";

const SignUp: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [succeess, setSuccess] = useState<string | null>(null);
  const navigate = useNavigate();
  const [processing, setProcessing] = useState<boolean>(false);

  const handleCreateAccount = async (event: React.FormEvent) => {
    event.preventDefault();
    setProcessing(true);
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      setProcessing(false);
      return;
    }
    try {
      const userCredentials = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredentials.user;

      setSuccess("Account created successfully");
      setProcessing(false);
    } catch (error: any) {
      console.error("Error signing up:", error);
      if (error.code === "auth/email-already-in-use") {
        setError(
          "This email is already in use. Please use a different email or log in."
        );
      } else {
        setError("An error occurred during sign-up. Please try again.");
      }
      setProcessing(false);
    }
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => setError(""), 5000);
    }
  }, [error]);

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      console.log("User signed in with Google:", user);
    } catch (error) {
      console.error("Error with Google sign-in:", error);
    }
  };

  return (
    <div className="relative flex flex-col justify-center items-center h-screen bg-white">
      <header className="absolute top-0 left-0 w-full py-4 px-5 flex justify-between items-center ">
        <img src={logo} alt="Logo" className="h-28" /> {/* Add logo */}
        <div className="flex flex-col sm:flex-row text-primary-lightest items-end justify-between sm:space-x-5 font-semibold text-xs sm:text-sm">
          <a
            href="mailto:Femmefamilyplanning@gmail.com"
            className="text-primary-lightest hover:underline"
          >
            Femmefamilyplanning@gmail.com
          </a>
          <a
            href="tel:+27714343982"
            className="text-primary-lightest hover:underline mb-2 sm:mb-0"
          >
            +27 71 434 3982
          </a>
        </div>
      </header>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-24 p-8 rounded-lg max-w-4xl w-full">
        {/* Illustration Section */}
        <div className="hidden lg:flex items-center justify-center">
          <img
            src={signupIllustration}
            alt="Sign Up Illustration"
            className="w-full h-auto"
          />
        </div>

        {/* Form Section */}
        <div>
          {succeess && (
            <h1 className="text-center mb-5 font-bold  bg-green-300 rounded-sm p-2">
              {succeess}
              <br />
              <span
                onClick={() => navigate("/login")}
                className="text-blue-400 underline cursor-pointer"
              >
                Login
              </span>
            </h1>
          )}
          <h2 className="text-2xl font-semibold text-center mb-6">
            Create your account
          </h2>
          {error && <div className="text-red-500 text-sm py-10">{error}</div>}
          <form onSubmit={handleCreateAccount}>
            <div className="w-full mb-7">
              <FloatLabel>
                <InputText
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setEmail(e.target.value)
                  }
                  className="mt-1 block w-full px-3 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                />
                <label htmlFor="email">Email</label>
              </FloatLabel>
            </div>

            <div className="w-full mb-7">
              <FloatLabel>
                <InputText
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPassword(e.target.value)
                  }
                  className="mt-1 block w-full px-3 py-3 border border-gray-300 rounded-md  placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                />
                <label htmlFor="password">Password</label>
              </FloatLabel>
            </div>

            <div className="w-full mb-5">
              <FloatLabel>
                <InputText
                  id="confirmPassword"
                  type="password"
                  value={confirmPassword}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setConfirmPassword(e.target.value)
                  }
                  className="mt-1 block w-full px-3 py-3 border border-gray-300 rounded-md  placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                />
                <label htmlFor="confirmPassword">Confirm Password</label>
              </FloatLabel>
            </div>

            <div className="mb-6">
              <button
                disabled={processing}
                type="submit"
                className="w-full py-2 px-4 bg-primary-lightest text-white font-semibold rounded-md shadow-md hover:bg-primary-lightd focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50"
              >
                {processing ? (
                  <>
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="inline mr-3 w-4 h-4 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      ></path>
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                    Processing...
                  </>
                ) : (
                  "Sign Up"
                )}
              </button>
            </div>
          </form>

          <div className="text-center mb-4">
            <p className="text-sm text-gray-600">
              Already have an account?{" "}
              <a
                href="/login"
                className="text-primary-lightest font-medium hover:underline"
              >
                Log in
              </a>
            </p>
          </div>

          <div className="relative my-4">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center text-sm">
              <span className=" px-2 text-gray-500 bg-gray-50">OR</span>
            </div>
          </div>

          <div>
            <button
              onClick={handleGoogleSignIn}
              className="w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-gray-700  hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary flex items-center justify-center"
            >
              <img
                src={googleIcon}
                alt="Google logo"
                className="w-5 h-5 mr-4"
              />
              Sign up with Google
            </button>
          </div>
        </div>
      </div>
      {/* Footer Links */}
      <footer className="absolute bottom-8 left-0 right-0 text-primary-lightest text-center text-sm">
        <a href="/privacy-policy" className="text-primary hover:underline mx-4">
          Privacy Policy
        </a>
        <span className="">|</span>
        <a
          href="/terms-of-service"
          className="text-primary hover:underline mx-4"
        >
          Terms of Service
        </a>
      </footer>

      {/* Support Button */}
      <button className="fixed flex items-center bottom-4 right-4 bg-primary-lightest text-white py-2 px-3 rounded-full shadow-md hover:bg-primary-lightd">
        <BsQuestionCircle className="mr-3" />
        Support
      </button>
    </div>
  );
};

export default SignUp;

import React, { useState } from "react";
import { FaTrash } from "react-icons/fa";
import Layout from "../components/Layout";
import cartIllustration from "../img/8882807.jpg";
import emptyCartIllustration from "../img/8882810.jpg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import Increment1 from "../incrementcounters/Increment1";
import Increment2 from "../incrementcounters/Increment2";
import Increment3 from "../incrementcounters/Increment3";
import { removeFromCart, clearCart } from "../cartSlice";
import { Link } from "react-router-dom";
import { PaystackButton } from "react-paystack";
import { FiCopy } from "react-icons/fi";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { LifeLine } from "react-loading-indicators";

interface Reference {
  message: string;
  redirecturl: string;
  reference: string;
  status: string;
  trans: string;
  transaction: string;
  trxref: string;
}

//PAYSTACK config interface
interface PaystackConfig {
  reference: string;
  email: string;
  amount: number;
  currency: string;
  publicKey: string;
}

interface PaystackSuccessAction {
  (reference: Record<string, any>): void;
}

const incrementComponents: React.FC[] = [Increment1, Increment2, Increment3];

const CartComponent: React.FC = () => {
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const bookingInfo = useSelector((state: RootState) => state.cart.bookingInfo);
  const [bookingRecieved, setBookingRecieved] = useState<boolean>(false);
  const [confirmBN, setConfirmBN] = useState<string>("");

  const dispatch = useDispatch();
  const [coupon, setCoupon] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const { count1, count2, count3 } = useSelector(
    (state: RootState) => state.counter
  );

  const counts: number[] = [count1, count2, count3];

  const handleRemoveItem = (index: number) => {
    dispatch(removeFromCart(index));
  };

  // Calculate subtotal, sales tax, and grand total
  const subtotal = cartItems.reduce((total, service, index) => {
    const count = counts[index % counts.length];
    return total + service.price * count;
  }, 0);

  const grandTotal = bookingInfo.totalToPay;

  //paystack configuration object
  // const publicKey: string = "pk_test_c213ffa60b5fb966b4c8dfb3e6315008cf560f8b";
  const publicKey: string = process.env
    .REACT_APP_PAYSTACK_TEST_PUBLIC_KEY as string;

  // Function to handle success action after payment
  const handlePaystackSuccessAction: PaystackSuccessAction = (reference) => {
    setLoading(true);
    const baseUrl = process.env.REACT_APP_BASE_URL;
    axios
      .post(`${baseUrl}/newbooking`, bookingInfo)
      .then((res) => {
        if (res.data.success) {
          dispatch(clearCart());
          setConfirmBN(reference.trxref);
          setBookingRecieved(true);
        }
      })
      .catch((error) => {
        console.error("Error creating booking:", error);
        if (error.response) {
          // Add error handling logic based on the error response status or data
        } else if (error.request) {
        } else {
          // Something happened in setting up the request that triggered an Error
        }
      });

    setLoading(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(confirmBN).then(() => {
      toast.success("Booking Number copied!", { position: "top-right" });
    });
  };

  // Function to handle Paystack close action
  const handlePaystackCloseAction = () => {
    console.log("closed");
  };

  const config: PaystackConfig = {
    reference: bookingInfo.bookingNumber,
    email: bookingInfo.emailAddress,
    amount: grandTotal * 100,
    currency: "ZAR",
    publicKey: publicKey,
  };
  // Props for PaystackButton
  const componentProps = {
    ...config,
    text: "PAY NOW",
    onSuccess: (reference: Record<string, any>) =>
      handlePaystackSuccessAction(reference),
    onClose: handlePaystackCloseAction,
  };

  return (
    <Layout>
      <div className="items-center justify-center pt-4 max-w-4xl mx-auto bg-white overflow-hidden">
        {!loading && (
          <div>
            {" "}
            <img
              src={
                cartItems.length > 0 ? cartIllustration : emptyCartIllustration
              }
              alt="Cart Illustration"
              className="mx-auto  w-40 h-40"
            />
            <Toaster position="top-right" reverseOrder={false} />
            <h1 className="text-center py-3  font-semibold">
              {cartItems.length > 0 ? (
                <span>{`Your cart has ${cartItems.length} ${
                  cartItems.length > 1 ? "Items" : "Item"
                }`}</span>
              ) : (
                <span>Your cart is Empty</span>
              )}
            </h1>
          </div>
        )}

        {cartItems.length > 0 ? (
          <>
            <div className="overflow-x-auto px-3">
              <table className="w-full text-sm text-left rtl:text-right dark:text-gray-400">
                <thead className="text-xs uppercase dark:bg-primary-light">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Service
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Price
                    </th>
                    <th scope="col" className="px-6 py-3 text-center">
                      Quantity
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {cartItems.map((service, index) => {
                    const IncrementComponent =
                      incrementComponents[index % incrementComponents.length];

                    const countIncrementer = counts[index % counts.length];

                    return (
                      <tr key={index} className="border-b border-primary-light">
                        <th
                          scope="row"
                          className="px-6 py-2 font-medium text-gray-500 whitespace-nowrap"
                        >
                          {service.item}
                        </th>
                        <td className="px-6 py-4">R{service.price}</td>
                        <td className="px-6 py-4 text-center">
                          {IncrementComponent ? <IncrementComponent /> : null}
                        </td>
                        <td className="pl-6 py-4 flex items-center justify-between">
                          <span className="mt-5">
                            R{service.price * countIncrementer}
                          </span>
                          <div className="relative group">
                            <FaTrash
                              size={20}
                              className="ml-10 mt-5 text-red-500"
                              onClick={() => handleRemoveItem(index)}
                            />
                            <span className="absolute hidden group-hover:block -top-8 right-0 bg-pink-500 text-white text-xs rounded px-2">
                              Remove service
                            </span>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            {/* Checkout Section */}
            <div className="md:flex md:justify-between mt-8 rounded-lg">
              <div className=""></div>
              <div className="px-3">
                <div className="flex justify-between items-center mb-4  border-b border-primary-light">
                  <span className="font-medium text-gray-500 ">Subtotal:</span>
                  <span className="font-semibold text-gray-500">
                    R{subtotal.toFixed(2)}
                  </span>
                </div>

                <div className="flex pb-2 justify-between items-center mb-4 border-b border-primary-light">
                  <span className="font-medium text-gray-500 ">Coupon:</span>
                  <span className="font-semibold text-gray-500">
                    <input
                      type="text"
                      className="mt-1 block w-32 h-8 ml-7 border border-gray-300 rounded-lg p-2 focus:ring-femmePink focus:border-femmePink"
                      value={coupon}
                      onChange={(e) => setCoupon(e.target.value)}
                    />
                  </span>
                </div>
                <div className="flex justify-between items-center mb-4 border-b border-primary-light">
                  <span className="font-medium text-gray-500 ">
                    Grand total:
                  </span>
                  <span className="font-semibold text-gray-500">
                    R{grandTotal.toFixed(2)}
                  </span>
                </div>
                {bookingInfo.transportationFee > 0 && (
                  <span className="font-semibold text-[12px] text-gray-500">
                    *Extra R100 for Transport fee
                  </span>
                )}
                <div className="py-5">
                  <PaystackButton
                    {...componentProps}
                    className="py-2 bg-primary-lightest w-full text-white font-semibold px-10 rounded-lg hover:hover:bg-primary-lightd"
                  />
                </div>
                {/* <div className="flex py-5 justify-end">
                  <button className="bg-primary-lightest text-white font-semibold py-2 px-10 rounded-lg hover:hover:bg-primary-lightd">
                    Check out
                  </button>
                </div> */}
              </div>
            </div>
          </>
        ) : (
          <div className="px-2 md:px-0">
            {!loading && (
              <div className=" flex items-center justify-center">
                <Link to={"/booking"}>
                  <button className="p-4 flex  bg-primary-lightest text-white py-2 rounded-lg hover:bg-primary-lightd">
                    Add New Booking Now
                  </button>
                </Link>
              </div>
            )}
            {loading && (
              <div className="flex items-center justify-center mt-60">
                <LifeLine
                  color="#ff5d8f"
                  size="medium"
                  text="Confirming Booking details"
                  textColor=""
                />
              </div>
            )}
            {bookingRecieved && !loading && (
              <div className="py-5 mb-4 px-2 md:px-2 flex flex-col items-center space-y-4 bg-primary-lightest rounded-lg mt-10">
                <h1 className="text-gray-50">
                  Your Booking has Been Received ✅
                </h1>
                <div className="flex items-center space-x-2">
                  <h1 className="text-gray-50 grid font-medium">
                    Booking number:{" "}
                    <span className="font-extrabold">{confirmBN}</span>
                  </h1>
                  <button
                    onClick={handleCopy}
                    className="flex mt-5 items-center space-x-1 text-primary-whitish hover:text-primary-light focus:outline-none"
                  >
                    <FiCopy size={20} />
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default CartComponent;

// ServicesList.tsx
import React from "react";

import { BiHealth } from "react-icons/bi";
import { FaPills } from "react-icons/fa";
import { FaCar } from "react-icons/fa";
import { MdOutlineLocalDrink } from "react-icons/md";
import { FaUserDoctor } from "react-icons/fa6";
import { FaSyringe } from "react-icons/fa";

// Define the types for the services
type Service = {
  name: string;
  price: number;
  icon: React.ReactNode;
};

const services: Service[] = [
  { name: "Injection", price: 400, icon: <FaSyringe size={25} /> },
  { name: "Oral", price: 400, icon: <MdOutlineLocalDrink size={25} /> },
  {
    name: "Morning after pill",
    price: 250,
    icon: <FaPills />,
  },
  {
    name: "Vitamin drip",
    price: 1500,
    icon: <BiHealth size={25} />,
  },
  {
    name: "Consultation",
    price: 400,
    icon: <FaUserDoctor size={25} />,
  },
  { name: "Transport", price: 100, icon: <FaCar size={25} /> },
];

const ServicesList: React.FC = () => {
  return (
    <div className=" bg-gray-100">
      {/* <h1 className="text-center text-2xl text-primary-lightest font-semibold">
        Booking your Appointment is as Easy as.., Yes You guessed It 😉
      </h1> */}
      <h1 className="text-center text-l py-6 uppercase text-primary-lightest font-semibold">
        available services
      </h1>
      <div className="flex space-x-4 p-4 overflow-x-auto justify-center">
        {services.map((service, index) => (
          <React.Fragment key={index}>
            <div className="flex-shrink-0 w-56 flex items-center p-4 bg-white border border-gray-200 rounded-lg transition-transform transform hover:scale-105 hover:shadow-lg hover:bg-gray-50">
              <div className="text-xl mr-4 text-gray-600">{service.icon}</div>
              <div className="flex-grow">
                <h3 className="text-lg font-semibold text-gray-800">
                  {service.name}
                </h3>
                <p className="text-gray-600 text-left">R{service.price}</p>
              </div>
            </div>
            {index < services.length - 1 && (
              <div className="w-px bg-gray-300 h-full"></div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default ServicesList;
